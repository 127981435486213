import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["form", "name"]

  connect() {
  }

  show() {
    this.formTarget.classList.remove("hidden")
    this.nameTarget.focus()
  }

  hide() {
    this.formTarget.classList.add("hidden")
  }

  clear() {
    this.nameTarget.value = ''
  }

  save(e) {
    e.preventDefault()

    const customer = {
      name: this.nameTarget.value,
    }
    const event = new CustomEvent("setCustomer", { detail: customer })
    window.dispatchEvent(event)

    this.clear()
    this.hide()
  }
}
