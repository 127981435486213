import { Controller } from 'stimulus'
import hotkeys from 'hotkeys-js'

const ESCAPE_KEY = 27

export default class extends Controller {
  static targets = ['container']

  connect() {
    const key = this.data.get("openWithKey")

    if (key) {
      hotkeys(key, (event, handler) => {
        this.open(event)
      })
    }
  }

  disconnect() {
    const key = this.data.get("openWithKey")

    if (key) {
      hotkeys.unbind(key)
    }
  }

  open(e) {
    e.preventDefault()
    this.containerTarget.classList.remove("hidden")
    document.querySelector("body").classList.add("overflow-y-hidden")

    const input = this.containerTarget.querySelector("input[type='text']")
    if (input) {
      input.focus()
    }
  }

  close(e) {
    if (e) {
      e.preventDefault();
    }

    this.containerTarget.classList.add("hidden")
    document.querySelector("body").classList.remove("overflow-y-hidden")
  }

  closeWithMouse(e) {
    if(e && e.type == "click" && e.target == this.containerTarget) {
      this.close(e)
    }
  }

  closeWithKeyboard(e) {
    if (e.keyCode === ESCAPE_KEY && !this.containerTarget.classList.contains(this.toggleClass)) {
      this.close(e);
    }
  }
}
