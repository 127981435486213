import { Controller } from "stimulus";

import DateRangePicker from "vanillajs-datepicker/js/DateRangePicker.js";

export default class extends Controller {
  connect() {
    new DateRangePicker(this.element, {
      allowOneSidedRange: true,
      calendarWeeks: true,
      clearBtn: true,
      format: "dd.mm.yyyy",
      language: "de",
      maxView: 2,
      showDaysOfWeek: true,
      todayHighlight: true,
      weekStart: 1,
      autohide: true,
    });
  }
}
