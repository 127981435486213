import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["input"]

  connect() {
  }
  
  setValue(event) {
    this.inputTarget.value = event.detail.name
  }

  showForm() {
    const event = new CustomEvent("displayCustomerForm")
    window.dispatchEvent(event)
  }
}
