import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content', 'button']

  changeFilter(e) {
    const filterValue = e.target.dataset.filterContentValue

    this.buttonTargets.forEach( target => target.classList.remove('btn-primary') )
    e.target.classList.add('btn-primary')

    if (filterValue == "") {
      this.contentTargets.forEach( target => target.classList.remove("hidden") )
      return true
    }

    const filteredContent = this.contentTargets.forEach( (target) => {
      if (target.dataset.content == filterValue) {
        target.classList.remove("hidden")
      } else {
        target.classList.add("hidden")
      }
    })
  }
}
