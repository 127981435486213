import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['closeButton']

  connect() {
    this.eventListener = this.closeButtonTarget.addEventListener('click', this.removeMessage)
  }

  disconnect() {
    this.closeButtonTarget.removeEventListener('click', this.eventListener)
  }

  removeMessage = (e) => {
    e.preventDefault()

    this.element.classList.add("opacity-0")

    window.setTimeout(() => {
      this.element.remove()
    }, 300)
  }
}
