import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['image', 'imagesOutput']

  connect() {
    const hiddenImageIds = []

    this.imageTargets.forEach( (image) => {
      image.addEventListener("click", (e) => {
        e.preventDefault()

        const imageId = e.target.dataset.imageid
        const indexOfImageId = hiddenImageIds.indexOf(imageId)

        if(indexOfImageId > -1) {
          hiddenImageIds.splice(indexOfImageId, 1) 
        } else {
          hiddenImageIds.push(imageId)
        }

        this.redrawImages(hiddenImageIds)
      })
    })
  }

  redrawImages(hiddenImageIds) {
    const imageShown = (id) => hiddenImageIds.indexOf(id) > -1

    this.imageTargets.forEach( (image) => {
      const checkMark = image.parentElement.querySelector('[data-checkmark]')
      if (imageShown(image.dataset.imageid)) {
        checkMark.classList.add("hidden")
      } else {
        checkMark.classList.remove("hidden")
      }
    })

    this.imagesOutputTarget.querySelectorAll("img").forEach( (image) => {
      if(imageShown(image.dataset.imageid)) {
        image.classList.add("hidden")
      } else {
        image.classList.remove("hidden")
      }
    })
  }
}
