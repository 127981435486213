// uppy
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import XHRUpload from '@uppy/xhr-upload'
import German from '@uppy/locales/lib/de_DE'
import Webcam from '@uppy/webcam'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/webcam/dist/style.css'

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['closeButton']

  connect() {
    const element = this.element

    this.uppy = Uppy({
      logger: Uppy.debugLogger,
      id: element.dataset.uppyId,
      locale: German,
      restrictions: {
        allowedFileTypes: ['.jpeg', '.jpg', '.png', '.pdf'],
      },
    })

    this.uppy.on("complete", (result) => {
      // if(result.failed.length == 0) {
      //   window.location.reload()
      // }
    })

    this.uppy.use(XHRUpload, {
      endpoint: element.dataset.uppyUploadPath,
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
      },
    })

    this.uppy.use(Dashboard, {
      inline: true,
      target: element,
      id: element.dataset.uppyId,
      height: 300,
      thumbnailWidth: 200,
    })

    this.uppy.use(Webcam, {
      target: Dashboard,
      onBeforeSnapshot: () => Promise.resolve(),
      modes: [
        'picture'
      ],
      mirror: false,
      locale: {
        smile: 'Cheeeeese!',
        takePicture: 'Foto aufnehmen',
        allowAccessTitle: 'Bitte erlaube den Zugriff auf die Kamera',
        allowAccessDescription: 'Um Fotos und Videos mit der integrierten Kamera zu erstellen, erlaube den Kamerazugriff für diese Website'
      }
    })

    this.uppy.on('complete', (result) => {
    })
  }

  disconnect() {
    this.uppy.close()
  }
}
