import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['output', 'input']

  connect() {
    const currentValue = this.inputTarget.value
    this.materials = JSON.parse(this.inputTarget.dataset.materials)
    this.inputTarget.addEventListener("change", this.updateRecommendedValue)

    this.showCurrentValue(this.materials, currentValue)
  }

  disconnect() {
    this.inputTarget.removeEventListener("change", this.updateRecommendedValue) 
  }

  updateRecommendedValue = (e) => {
    this.showCurrentValue(this.materials, e.target.value)
  }

  showCurrentValue(materials, currentValue) {
    const selectedMaterial = materials.find( (material) => {
      return material.id == currentValue
    })

    if (selectedMaterial) {
      this.outputTarget.innerText = selectedMaterial.recommended_value
    }
  }
}
