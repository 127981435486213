import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button', 'menu']

  connect() {
    this.buttonEventListener = this.buttonTarget.addEventListener('click', (e) => {
      e.preventDefault()
      this.toggleMenu()
    })
  }

  disconnect() {
    this.buttonTarget.removeEventListener('click', this.buttonEventListener)
  }

  toggleMenu() {
    if(this.menuTarget.classList.contains('hidden')) {
      this.openMenu()
    } else {
      this.closeMenu()
    }
  }

  openMenu() {
    this.menuTarget.classList.remove('hidden')

    // Dropup calculations. Needed when the dropdown is inside a table
    // because the table has overflow-hidden and the dropdown would not
    // be visible if its at the end of the table
    const wrappingTable = this.menuTarget.closest("table")

    if (wrappingTable !== null) {
      const tableCoords = wrappingTable.getBoundingClientRect()
      const menuCoords = this.menuTarget.getBoundingClientRect()

      if(menuCoords.bottom > (tableCoords.height + tableCoords.top)) {
        const newTopValue = "-" + (menuCoords.height + 10) + "px"
        this.menuTarget.style.top = newTopValue
      }
    }
  }

  closeMenu() {
    this.menuTarget.classList.add('hidden')
    this.menuTarget.style.top = "2rem"
  }

  hideOnClick(event) {
    if (this.element.contains(event.target) === false) {
      this.closeMenu()
    }
  }
}
