import consumer from "./consumer"

const generateAppearanceId = () => {
  const randomInteger = parseInt(Math.random()*10000)
  const date = new Date()
  const minute = date.getMinutes()
  const seconds = date.getSeconds()

  return `${randomInteger}-${minute}-${seconds}`
}

let appearanceId;

const sessionAppearanceId = sessionStorage.getItem("appearanceId")

if (sessionAppearanceId == undefined || sessionAppearanceId == null || sessionAppearanceId == "") {
  appearanceId = generateAppearanceId()
} else {
  appearanceId = sessionStorage.getItem("appearanceId")
}

consumer.subscriptions.create({ channel: "AppearanceChannel", session_id: appearanceId }, {
  initialized() {
    this.update = this.update.bind(this)
  },

  connected() {
    const sessionAppearanceId = sessionStorage.getItem("appearanceId")

    if (sessionAppearanceId == undefined) {
      sessionStorage.setItem("appearanceId", appearanceId)
    }

    this.install()
    this.update()
  },

  disconnected() {
    this.uninstall()
  },

  rejected() {
    this.uninstall()
  },

  update(e) {
    this.appear() 
  },

  unload() {
    this.disappear()
  },

  appear() {
    this.perform("appear", { appearing_on: this.appearingOn(), session_id: sessionStorage.getItem("appearanceId") })
  },

  install() {
    // window.addEventListener("focus", this.update)
    document.addEventListener("turbo:load", this.update)
  },

  uninstall() {
    this.perform("disappear", { session_id: sessionStorage.getItem("appearanceId") })

    // window.removeEventListener("focus", this.update)
    document.removeEventListener("turbo:load", this.update)
  },

  documentIsActive() {
    return ( document.visibilityState == "visible" && document.hasFocus() )
  },

  appearingOn() {
    return window.location.pathname
  },
})
