import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['wrapper']

  connect() {
    this.timeout = ''
  }

  show() {
    this.timeout = setTimeout(() => {
      if(this.hasWrapperTarget) {
        this.wrapperTarget.classList.add('opacity-100') 
      }
    }, 150)
  }

  hide() {
    if(this.hasWrapperTarget) {
      this.wrapperTarget.classList.remove('opacity-100') 
    }
    clearTimeout(this.timeout)
  }
}
