import { Controller } from 'stimulus'

export default class extends Controller {
  unsaved = false
  skipAlert = false

  markUnsavedContent = (e) => {
    this.unsaved = true
    this.skipAlert = false
  }

  notifyUser = (e) => {
    if (this.unsaved && this.skipAlert == false) {
      if(!confirm("Es sind ungespeicherte Änderungen auf dieser Seite vorhanden. Seite trotzdem verlassen?")) {
        e.preventDefault()
      }
    }

    this.skipAlert = false
  }

  skip(e) {
    this.skipAlert = true
  }

  connect() {
    const inputs = this.element.querySelectorAll("input")
    const selects = this.element.querySelectorAll("select")
    const textareas = tinymce.editors

    for(let i = 0; i < inputs.length; i++) {
      const element = inputs[i]

      element.addEventListener("change", this.markUnsavedContent)
    }

    for(let i = 0; i < selects.length; i++) {
      const element = selects[i]

      element.addEventListener("change", this.markUnsavedContent)
    }

    for(let i = 0; i < textareas.length; i++) {
      const element = textareas[i]

      element.on("change", this.markUnsavedContent)
    }

    window.addEventListener("onbeforeunload", this.notifyUser);
    window.addEventListener("turbo:before-visit", this.notifyUser);
  }

  disconnect() {
    const inputs = this.element.querySelectorAll("input")
    const selects = this.element.querySelectorAll("select")

    for(let i = 0; i < inputs.length; i++) {
      const element = inputs[i]

      element.removeEventListener("change", this.markUnsavedContent)
    }

    for(let i = 0; i < selects.length; i++) {
      const element = selects[i]

      element.removeEventListener("change", this.markUnsavedContent)
    }

    window.removeEventListener("onbeforeunload", this.notifyUser);
    window.removeEventListener("turbo:before-visit", this.notifyUser);
  }
}
