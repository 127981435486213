import { Controller } from "stimulus";

// Import TinyMCE
import tinymce from "tinymce/tinymce";
import "../tinymce/de";

// Default icons are required for TinyMCE 5.3 or above
import "tinymce/icons/default";

// A theme is also required
import "tinymce/themes/silver";

// Any plugins you want to use has to be imported
import "tinymce/plugins/paste";
import "tinymce/plugins/link";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/help";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/charmap";

import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/ui/oxide/content.inline.min.css";
import "tinymce/skins/ui/oxide/content.mobile.min.css";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    tinymce.init({
      target: this.inputTarget,
      skin: false, // tinymce should not load skins (loaded manually)
      content_css: false, // tinymce should not load content's css (loaded manually)
      content_style:
        "#tinymce { font-family: sans-serif; } .bordered-table { border-collapse: collapse; border-style: hidden;   } .bordered-table td,    .bordered-table th {      @apply border border-solid border-gray-500;      @apply p-1;    }", // override serif font
      language: "de",
      language_url: "",
      menubar: "edit",
      plugins: ["lists link", "table paste help wordcount", "charmap"],
      toolbar:
        "undo redo | formatselect | bold italic | charmap | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | table bordered-table | help",
      setup: (editor) => {
        editor.ui.registry.addButton("bordered-table", {
          tooltip: "Rahmen zu Tabelle hinzufügen",
          text: "Rahmen",
          onAction: function () {
            const element = tinymce.activeEditor.selection.getNode();
            const table = editor.dom.getParent(element, "table");

            if (tinymce.activeEditor.dom.hasClass(table, "bordered-table")) {
              tinymce.activeEditor.dom.removeClass(table, "bordered-table");
            } else {
              tinymce.activeEditor.dom.addClass(table, "bordered-table");
            }
          },
        });
      },
    });
  }
  disconnect() {
    tinymce.remove();
  }
}
