// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
import "@hotwired/turbo-rails";
require("@rails/activestorage").start();
require("channels");

// load stimulus controllers dir
import "controllers";

import "../css/application.css";

// datepicker
import Datepicker from "vanillajs-datepicker/js/Datepicker.js";
import de from "vanillajs-datepicker/js/i18n/locales/de.js";

Object.assign(Datepicker.locales, de);

document.addEventListener("turbo:load", function () {
  // datepicker
  const datepickerInputs = document.querySelectorAll("[data-datepicker]");
  if (datepickerInputs.length > 0) {
    datepickerInputs.forEach((input) => {
      new Datepicker(input, {
        calendarWeeks: true,
        clearBtn: true,
        format: "dd.mm.yyyy",
        language: "de",
        maxView: 2,
        todayHighlight: true,
        weekStart: 1,
        autohide: true,
        orientation: "auto bottom",
      });
    });
  }
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
