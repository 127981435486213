import { Controller } from 'stimulus'
import autocomplete from 'autocomplete.js'
import Mustache from 'mustache'

export default class extends Controller {
  static targets = ['field', 'suggestionTemplate', 'noSuggestionsTemplate']

  connect() {
    const source = JSON.parse(this.data.get('mysource'))
    const suggestionTemplate = this.suggestionTemplateTarget.innerHTML;
    const noSuggestionsTemplate = this.noSuggestionsTemplateTarget.innerHTML;
    const dropdownWrapperClass = this.data.get('wrapper-class')
    const rootClass = this.data.get('root-class')

    this.ac = autocomplete(this.fieldTarget, {
      hint: false,
      debug: false,
      cssClasses: {
        dropdownMenu: dropdownWrapperClass,
        root: rootClass,
      },
    }, [{
      source: (query, callback) => {
        const regex = new RegExp(query, 'gi')

        const results = source.filter( (element) => {
          return element.name.match(regex)
        })

        callback(results)
      },
      debounce: 200,
      templates: {
        suggestion: (suggestion) => {
          return Mustache.render(suggestionTemplate, suggestion);
        },
        empty: () => {
          return Mustache.render(noSuggestionsTemplate)
        }
      }
    }])

    this.ac.on("autocomplete:selected", (event, suggestion, dataset, context) => {
      this.ac.autocomplete.setVal(suggestion.name)
    })
  }
}
