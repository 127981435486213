import { Controller } from 'stimulus'
import Choices from 'choices.js'

export default class extends Controller {
  static targets = ['field']

  connect() {
    const url = this.fieldTarget.dataset.autocompleteSelectUrl
    let timeout = null

    this.choices = new Choices(this.fieldTarget, {
      searchResultLimit: 15
    });

    if (!url) return false

    this.element.addEventListener("search", (e) => {
      clearTimeout(timeout)
      const value = e.detail.value

      timeout = setTimeout(() => {
        const newChoices = async () => {
          try {
            const items = await fetch(url + "?query=" + value)
            return items.json();
          } catch (err) {
            console.error(err);
          }
        }

        this.choices.setChoices(newChoices, 'id', 'name', true).then( () => {
          this.choices.input.element.focus()
        })
      }, 600)
    })
  }
}
